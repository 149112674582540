<template>
  <div id="page-user-view" class="p-6">
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('Merchant Pickup Points')}}</h2>
      <span v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'sales'" class="font-medium text-primary cursor-pointer">
        <vs-button
          @click="newPickupPoint('Add Pickup Point')"
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          size="large"
          v-if="!(merchant.is_self_signup && merchant.name === 'Entrepreneur Merchant' && ($store.state.AppActiveUser.userRole === 'sales' || $store.state.AppActiveUser.userRole === 'customer-support'))"
        >
        </vs-button>
      </span>
    </div>
    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      :data="pickupPoints"
      :tableLoader="tableLoader"
      :hideHeader="true"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Governorate')}}</shipblu-th>
        <shipblu-th>{{$t('City')}}</shipblu-th>
        <shipblu-th>{{$t('Zone')}}</shipblu-th>
        <shipblu-th>{{$t('Address')}}</shipblu-th>
        <shipblu-th>{{$t('Default')}}</shipblu-th>
        <shipblu-th class="flex justify-center" v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'sales'">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td class="order-3" :data="data[indextr].governorate">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Governorate')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].address.zone ? $t(data[indextr].address.zone.city.governorate.name) : 'N/A' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].city">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('City')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].address.zone ? $t(data[indextr].address.zone.city.name) : 'N/A' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].zone">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Zone')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].address.zone ? $t(data[indextr].address.zone.name) : 'N/A' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].address">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Address')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].address.line_1 }} {{ data[indextr].address.line_2 }}
            </p>
          </shipblu-td>
          <shipblu-td class="order-7" :data="data[indextr].is_default">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Default')}}</p>
            <vs-radio v-if="editable" name="reason" v-validate="'required'" v-model="pickupPoint" :vs-value="data[indextr]"></vs-radio>
            <span v-else-if="!editable && data[indextr].is_default">
              <feather-icon
                icon="CheckCircleIcon"
                svgClasses="h-6 w-7"
                class="text-green"
              />
            </span>
          </shipblu-td>
          
          <shipblu-td class="order-2" style="vertical-align: middle;" v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'sales'">
            <div class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span> 
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item :disabled="data[indextr].is_default" @click="updatePickupPoint(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="CheckCircleIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Set Default')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item :disabled="data[indextr].is_default" @click="deletePickupPoint(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-pickup-point :governorates="governorates" :cities="cities" :zones="zones"
    :type="type" :pickupPointModal="pickupPointModal" @pickupPointModal="pickupPointModal = $event" :address="address" @addPickupPoint="addPickupPoint"></add-pickup-point>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import AddPickupPoint from './AddPickupPoint.vue'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import i18nData from '../../../i18n/i18nData.js'
import common from '../../../assets/utils/common.js'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  props: ['merchant'],
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      editable: false,
      pickupPoints: [],
      selected: [],
      pickupPoint: '',
      deleteData: {},
      pickupPointModal: false,
      type: 'Add Pickup Point',
      address: {
        city: {
          id: '',
          name: ''
        },
        governorate: {
          id: '',
          name: ''
        },
        address: {
          line_1: '',
          zone: ''
        }
      },
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      governorates: [],
      cities: [],
      zones: [],
      tableLoader: false,
      merchantID: ''
    }
  },
  watch : {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.loadPickupsPoints()
    },
    'address.governorate' (val) {
      if (this.address.governorate) {
        this.loadCities(val.id)
      }
    },
    'address.city' (val) {
      if (this.address.city) {
        this.loadZones(val.id)
      }
    }
  },
  methods: {
    loadGovernorates () {
      const govPromise = common.loadGovernorates(this)
      govPromise.then((results) => {
        this.governorates = results.data
      })
    },
    loadCities (governorateID) {
      const cityPromise = common.loadCities(governorateID, this)
      cityPromise.then((results) => {
        this.cities = results.data
      })
    },
    loadZones (cityID) {
      const zonePromise = common.loadZones(cityID, this)
      zonePromise.then((results) => {
        this.zones = results.data.filter(item => item.id !== 284)
      })
    },
    loadPickupsPoints () {
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/merchants/${this.merchantID}/pickup-points/${query}`, 'get', null, true,
        (response) => {
          this.pickupPoints = response.data.results
          this.totalRows = response.data.count
          this.pickupPoints.forEach(element => {
            if (element.is_default) {
              this.pickupPoint = element
            }
          })
          this.tableLoader = false
        }
      )
    },
    newPickupPoint (type) {
      this.type = type
      this.address = {
        address: {
          line_1: '',
          zone: ''
        }
      }
      this.pickupPointModal = true
    },
    updatePickupPoint (data) {
      sendRequest(false, false, this, `api/v1/merchants/${this.merchantID}/pickup-points/${data.id}/`, 'patch', {is_default: true}, true,
        () => {
          this.editable = false
          this.loadPickupsPoints()
        }
      )
    },
    addPickupPoint () {
      const address = { 
        address: {
          line_1: this.address.address.line_1,
          line_2: this.address.address.line_2,
          zone: this.address.address.zone.id
        }
      }
      sendRequest(false, false, this, `api/v1/merchants/${this.merchantID}/pickup-points/`, 'post', address, true,
        () => {
          this.pickupPointModal = false
          this.loadPickupsPoints()
        }
      )
    },
    deletePickupPoint (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/merchants/${this.merchantID}/pickup-points/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.loadPickupsPoints()
        }
      )
    }
  },
  components: { 
    AddPickupPoint,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    if (this.$store.state.AppActiveUser.userRole !== 'commercial-admin') {
      this.loadGovernorates()
    }
    this.merchantID = this.$route.params.merchantID
    this.loadPickupsPoints()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
