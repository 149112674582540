<template>
  <div id="page-user-view" class="px-4">
    <div class="vx-row">
      <div v-if="merchantInsurance.insurance_package && merchantInsurance.insurance_package.id && merchantInsurance.insurance_package.id !== 1" class="vx-col w-full mb-base">
        <h2 class="title mb-10">{{$t('Your Active Insurance Plan')}}</h2>
        <div class="md:flex justify-between items-center p-5 grid grid-cols-1 gap-4 border-blue-100 active-insurance w-full">
          <div>
            <p class="font-semibold text-xl">{{merchantInsurance.insurance_package.insurance_fees}}%</p>
            <p class="font-normal text-sm">{{$t('Insurance Fees / Order')}}</p>
          </div>
          <div>
            <p class="font-semibold text-xl">{{merchantInsurance.insurance_package.payout_coverage}}%</p>
            <p class="font-normal text-sm">{{$t('Payout Coverage')}}</p>
          </div>
          <div>
            <p class="font-semibold text-xl">{{merchant.default_order_declared_value}} {{$t('EGP')}}</p>
            <p class="font-normal text-sm">{{$t('Your default declared value: ')}}</p>
          </div>
        </div>
      </div>
    </div>
    <shipblu-card :cardLoading="merchantInsuranceHistoryLoading" v-if="merchantInsuranceHistory.length > 0" class="mt-4 p-5">
      <div class="flex mb-2 w-full justify-between items-center">
        <h2 class="title mb-6">{{$t('Coverage History')}}</h2>
      </div>
      <div v-for="(item, index) in merchantInsuranceHistory" :key="index" class="md:ml-4">
        <div class="rounded-lg">
          <ul class="vx-timeline h-20 w-full mb-0 xs:mb-24 sm:mb-0">
            <li>
              <div class="timeline-icon h-2 w-2 inline-block rounded-full mr-2 bg-grey"></div>
              <div class="vx-row flex">
                <div class="vx-col w-1/3 sm:w-1/3 xs:w-full xs:mb-1">
                  <small class="activity-e-status block">{{$t("Date")}}</small>
                  <p class="font-medium lg:text-sm mr-2">{{ new Date(item.created).toLocaleDateString('fr-CA') }} {{ new Date(item.created).toLocaleTimeString() }}</p>
                </div>
                <div class="vx-col xl:pl-0 lg:w-1/3 lg:pr-2 sm:w-1/3 sm:text-left xs:text-left xs:w-full xs:mb-1">
                  <small class="activity-e-time">{{$t("Insurance Package Name")}}</small>
                  <p class="font-medium lg:text-sm mr-2">{{item.insurance_package ? item.insurance_package.name + ` (${item.insurance_package.payout_coverage}% ${$t(' payout coverage')})` : '' }}</p>
                </div>
                <div class="vx-col xl:w-1/3 lg:w-1/4 sm:w-1/3 sm:text-left xs:text-left xs:w-full xs:mb-1">
                  <small class="activity-e-time">{{$t("Effective Date")}}</small>
                  <p class="font-medium lg:text-sm">{{ item.effective_date ? item.effective_date : '' }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </shipblu-card>
  </div>
</template>
<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'

export default {
  data () {
    return {
      merchant: {},
      merchantInsurance: {
        insurance_package: {}
      },
      merchantInsuranceHistory: [],
      merchantInsuranceHistoryLoading: false
    }
  },
  methods: {
    MerchantInsuranceHistory () {
      this.merchantInsuranceHistoryLoading = true
      sendRequest(true, false, this, `api/v1/tracking/merchants/${this.$route.params.merchantID}/insurance-packages/`, 'get', null, true,
        (response) => {
          this.merchantInsuranceHistory = response.data.results
          this.merchantInsuranceHistoryLoading = false
        }        
      )
    },
    loadInsurancePlans () {
      sendRequest(false, false, this, `api/v1/merchants/${this.$route.params.merchantID}/insurance-packages/`, 'get', null, true,
        (response) => { 
          this.merchantInsurance = response.data
        }        
      )
    },
    loadMerchantData () {
      sendRequest(false, false, this, `api/v1/merchants/${this.$route.params.merchantID}/`, 'get', null, true,
        (response) => {
          this.merchant = response.data
        }
      )
    }
  },
  components: {
    ShipbluCard 
  },
  created () {
    this.loadInsurancePlans()
    this.loadMerchantData()
    this.MerchantInsuranceHistory()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/components/vxTimeline.scss";

.vx-timeline {
  margin-left: 0.5rem;
  padding-left: 40px;
  border-left: 2px dotted #e5e8eb;
}
li {
  position: relative;
  .timeline-icon {
    position: absolute;
    top: 0;
    left: -3.2rem !important;
    border-radius: 50%;
    padding: 0px;
    padding: 0.3rem !important;
    padding-bottom: 0.4rem;
  }
}
.title {
  color:#3169fc;
  background-color: transparent !important;
}
.insurance-card{
  border: #D6D6D6 1px solid;
  width:298px;
  border-radius: 9px;
}
.active-insurance {
  border: #3169fc 1px solid;
  width:915px;
  border-radius: 9px;
}
</style>
