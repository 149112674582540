<template>
  <div id="page-user-view" class="p-6">
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('Merchant Bank Deposits')}}</h2>
      <span v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'finance'" class="font-medium text-primary cursor-pointer">
        <vs-button
          @click="newBankDeposit('Add')"
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          size="large"
        >
        </vs-button>
      </span>
    </div>
    <shipblu-table
      :sst="true"
      v-model="selected"
      multiple
      pagination
      :max-items="maximumItems"
      :data="bankDeposits"
      :tableLoader="tableLoader"
      :hideHeader="true"
    >
      <template slot="thead">
        <shipblu-th>{{$t('ID')}}</shipblu-th>
        <shipblu-th>{{$t('Bank Reference Number')}}</shipblu-th>
        <shipblu-th>{{$t('Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Deposited On')}}</shipblu-th>
        <shipblu-th>{{$t('Deposit Slip')}}</shipblu-th>
        <shipblu-th>{{$t('Statement')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole !== 'customer-support' && $store.state.AppActiveUser.userRole !== 'sales'" class="order-1 flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>
          
          <shipblu-td class="order-4" :data="data[indextr].id">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('ID')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].id }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].bank_reference_number">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Bank Reference Number')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].bank_reference_number }}
            </p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].amount" class="text-left sm:order-3 order-10 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ (data[indextr].amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].deposit_date">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Deposit Date')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].deposit_date }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].link_to_deposit_slip">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Deposit Slip')}}</p>
            <a class="sm:text-base text-sm" @click="downloadBankDeposit(data[indextr])" target="_blank">{{$t('Download')}}</a>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].statement">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Statment')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].statement.number }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td v-if="$store.state.AppActiveUser.userRole !== 'customer-support' && $store.state.AppActiveUser.userRole !== 'sales'"  class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span> 
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item :disabled="data[indextr].is_default" @click="newBankDeposit('Edit', data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item :disabled="data[indextr].is_default" @click="deletePickupPoint(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-bank-deposit :type="type" :bankDepositModal="bankDepositModal" :statements="statements"
    @bankDepositModal="bankDepositModal = $event" :bankDeposit="bankDeposit" @loadBankDeposits="loadBankDeposits"
    ></add-bank-deposit>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import AddBankDeposit from './AddBankDeposit.vue'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import i18nData from '../../../i18n/i18nData.js'
import common from '../../../assets/utils/common.js'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      editable: false,
      bankDeposits: [],
      selected: [],
      pickupPoint: '',
      deleteData: {},
      bankDepositModal: false,
      type: 'Add Pickup Point',
      bankDeposit: {
        bank_reference_number: '',
        number: '',
        amount: ''
      },
      merchantID: '',
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      statements: [],
      file_URl: '',
      tableLoader: false,
      file_key: ''
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-merchant-view`,
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadBankDeposits()
    }
  },
  methods: {
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadStatements () {
      sendRequest(false, false, this, `api/v1/billing/statements/?merchant=${this.$route.params.merchantID}&limit=100`, 'get', null, true,
        (response) => {
          this.statements = response.data.results
        }
      )
    },
    loadBankDeposits () {
      this.tableLoader = true
      const query = `?statement__merchant=${this.$route.params.merchantID}&offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/billing/bank-deposits/${query}`, 'get', null, true,
        (response) => {
          this.bankDeposits = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    downloadBankDeposit (data) {
      sendRequest(false, false, this, `api/v1/billing/bank-deposits/${data.id}/pdf/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    newBankDeposit (type, data) {
      this.type = type
      if (type === 'Add') {
        this.bankDeposit = {
          bank_reference_number: '',
          amount: '',
          deposit_slip_key: '',
          deposit_date: '',
          statement: ''
        }
      } else {
        this.bankDeposit = data
      }
      this.bankDepositModal = true
    },
    deletePickupPoint (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/billing/bank-deposits/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.loadBankDeposits()
        }
      )
    }
  },
  components: { 
    AddBankDeposit,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStatements()
    this.loadBankDeposits()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
