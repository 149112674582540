<template>
  <div id="page-user-view" class="p-6">
    <h2 class="mb-4">{{$t('Merchant Statements')}}</h2>
    <div class="vx-row" v-if="$store.state.AppActiveUser.userRole !== 'finance'">
      <div class="vx-col w-full lg:w-full mb-base">
        <cash-on-way :summaryCashamount="summaryCashamount"></cash-on-way>
      </div>
    </div>
    <shipblu-table
      :sst="true"
      orders
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      :data="statements"
      :hideHeader="true"
      :tableLoader="tableLoader"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Statement Date')}}</shipblu-th>
        <shipblu-th>{{$t('Statement Number')}}</shipblu-th>
        <shipblu-th>{{$t('Balance Due')}}</shipblu-th>
        <shipblu-th>{{$t('Cash Collection Balance')}}</shipblu-th>
        <shipblu-th>{{$t('Payout')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole !== 'finance'">{{$t('Accounting Status')}}</shipblu-th>
        <shipblu-th>{{$t('Statement PDF')}}</shipblu-th>
        <shipblu-th class="flex justify-center" v-if="$store.state.AppActiveUser.userRole !== 'customer-support' && $store.state.AppActiveUser.userRole !== 'sales'">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].id">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Statement Date')}}</p>
            <span class="sm:text-base text-sm text-darkblue">{{ new Date(data[indextr].created).toLocaleDateString('fr-CA')}}</span>
          </shipblu-td>

          <shipblu-td :data="data[indextr].number" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].number }}
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].net_service_fees_due">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Balance Due')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].v2 ? Number(data[indextr].v2.balance_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_service_fees_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].net_cod_due">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Cash Collection Balance')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].v2 ? Number(data[indextr].v2.cash_closing_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_cod_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].net_cod_due">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Payout')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].v2 ? Number(data[indextr].v2.payout).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_cod_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].status" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td class="order-9" v-if="$store.state.AppActiveUser.userRole !== 'finance'" :data="data[indextr].accounting_status" :style="`color: ${getAccountStatusColor(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Accounting Status')}}</p>
            <span class="capitalize sm:text-base text-sm">{{ data[indextr].accounting_status }}</span>
          </shipblu-td>

          <shipblu-td class="order-10" :data="data[indextr].pdf_link">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Statement PDF')}}</p>
            <a @click="downloadStatement(data[indextr])" target="_blank" class="hover:underline sm:text-base text-sm">{{$t('PDF')}}</a> / 
            <a @click="downloadExcelStatement(data[indextr])" target="_blank" class="hover:underline sm:text-base text-sm">{{$t('Excel')}}</a> 
          </shipblu-td>
          
          <shipblu-td class="order-2" v-if="$store.state.AppActiveUser.userRole !== 'customer-support' && $store.state.AppActiveUser.userRole !== 'sales'">
            <div class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span> 
                <vs-dropdown-menu style="min-width: 190px;">
                  <vs-dropdown-item @click="generatePDF(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="FilePlusIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Generate PDF/Excel')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="regenerateStatement(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="FilePlusIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Regenerate Statement')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item :disabled="data[indextr].is_default" @click="newStatement('Edit', data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item :disabled="data[indextr].is_default" @click="deleteStatement(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    <add-statement @file_url="file_url = $event" @loadStatements="loadStatements"
    :type="type" :statementModal="statementModal" @statementModal="statementModal = $event" :statement="statement" @addStatement="addStatement"
    @updateStatement="updateStatement"></add-statement>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData'
import AddStatement from './AddStatement.vue'
import Discounts from './Discounts.vue'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import CashOnWay from '../../merchant/components/CashOnWay.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      editable: false,
      statements: [],
      selected: [],
      deleteData: {},
      statementModal: false,
      type: 'Add',
      discountType: 'Add',
      statement: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      file_URl: '',
      statementData: {},
      tableLoader: false,
      summaryCashamount: ''
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-merchant-view`,
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadStatements()
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadStatements()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getAccountStatusColor (order) {
      return common.getOrderStatusColor(order.accounting_status)
    },
    loadStatements () {
      this.tableLoader = true
      const query = `&offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/billing/statements/?merchant=${this.$route.params.merchantID}${query}`, 'get', null, true,
        (response) => {
          this.statements = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    downloadStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/pdf/`, 'get', null, true,
        (response) => {
          this.file_URl = response.data.file_url_v2 !== '' ? response.data.file_url_v2 : response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    downloadExcelStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/excel/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url_v2 !== '' ? response.data.file_url_v2 : response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    generatePDF (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/pdf/`, 'post', {}, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['New PDF has been generated successfully. Click download!'],
            position: 'top-center'
          })
        }
      )
    },
    regenerateStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/regenerate/`, 'get', null, true,
        () => {
          this.$vs.notify({
            color: 'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text: i18nData[this.$i18n.locale]['Statements has been regenerated successfully'],
            position: 'top-center'
          })
        }
      )
    },
    newStatement (type, data) {
      this.type = type
      if (type === 'Add') {
        this.statement = {
          number: '',
          net_service_fees_due: '',
          net_cod_due: '',
          status: '',
          accounting_status: '',
          expected_net_cod_deposit_date: '',
          pdf_link: '',
          merchant: '',
          discount: ''
        }
      } else {
        this.statement = data
      }
      this.statementModal = true
    },
    addStatement () {
      const statement = { 
        number: this.statement.number,
        net_service_fees_due: this.statement.net_service_fees_due,
        net_cod_due: this.statement.net_cod_due,
        status: this.statement.status,
        accounting_status: this.statement.accounting_status,
        expected_net_cod_deposit_date: this.statement.expected_net_cod_deposit_date,
        pdf_link: this.file_url,
        merchant: this.$route.params.merchantID
      }
      sendRequest(false, false, this, 'api/v1/billing/statements/', 'post', statement, true,
        () => {
          this.statementModal = false
          this.loadStatements()
        }
      )
    },
    updateStatement () {
      const statement = { 
        number: this.statement.number,
        net_service_fees_due: this.statement.net_service_fees_due,
        net_cod_due: this.statement.net_cod_due,
        status: this.statement.status,
        accounting_status: this.statement.accounting_status,
        expected_net_cod_deposit_date: this.statement.expected_net_cod_deposit_date,
        pdf_link: this.file_url,
        merchant: this.$route.params.merchantID,
        opening_balance: this.statement.opening_balance
      }
      sendRequest(false, false, this, `api/v1/billing/statements/${this.statement.id}/`, 'patch', statement, true,
        () => {
          this.statementModal = false
          this.loadStatements()
        }
      )
    },
    deleteStatement (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/billing/statements/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.loadStatements()
        }
      )
    },
    summaryCash () {
      sendRequest(true, false, this, `api/v1/billing/statements/summary/?merchant_id=${this.$route.params.merchantID}`, 'get', null, true,
        (response) => {
          this.summaryCashamount = response.data
        }
      )
    }
  },
  components: { 
    AddStatement,
    Discounts,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    CashOnWay,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadStatements()
    if (this.$store.state.AppActiveUser.userRole !== 'finance') this.summaryCash()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
