<template>
  <div id="page-user-view" class="p-6">
    <div class="vx-col flex-1" id="account-info-col-1">
      <form>
        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2 mb-base">
            <h2>{{$t('Payout Methods')}}</h2>
          </div>
        </div>
        <shipblu-table
          :sst="true"
          multiple
          v-model="selected"
          pagination
          :max-items="maximumItems"
          :data="payouts"
          :tableLoader="tableLoader"
          :hideHeader="true"
        >
          <template slot="thead">
            <shipblu-th>{{$t('Type')}}</shipblu-th>
            <shipblu-th>{{$t('Account Name')}}</shipblu-th>
            <shipblu-th>{{$t('Wallet Number')}}</shipblu-th>
            <shipblu-th>{{$t('Bank Name')}}</shipblu-th>
            <shipblu-th>{{$t('Account Number')}}</shipblu-th>
            <shipblu-th>{{$t('Default')}}</shipblu-th>
          </template>

          <template slot-scope="{ data }">
            <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <shipblu-td :data="data[indextr].type">
                {{ data[indextr].bank ? $t('Bank') : data[indextr].wallet ? $t('Wallet') : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].walletNumber">
                {{ data[indextr].bank ? data[indextr].bank.creditor_name : '' }}
              </shipblu-td>
              
              <shipblu-td :data="data[indextr].walletNumber">
                {{ data[indextr].wallet ? data[indextr].wallet.number : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].bankName">
                {{ data[indextr].bank ? data[indextr].bank.name : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].accountNumber">
                {{ data[indextr].bank ? data[indextr].bank.account_number : '' }}
              </shipblu-td>

              <shipblu-td :data="data[indextr].is_active">
                <span v-if="data[indextr].is_active">
                  <feather-icon
                    icon="CheckCircleIcon"
                    svgClasses="h-6 w-7"
                    class="text-green"
                  />
                </span>
              </shipblu-td>
            </shipblu-tr>
          </template>
        </shipblu-table>
      </form>
    </div>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      payouts: [],
      selected: [],
      payoutModal: false,
      offset: 0,
      totalRows: 0,
      tableLoader: false,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1
    }
  },
  watch : {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadPayouts()
    }
  },
  methods: {
    loadPayouts () {
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&merchant=${this.$route.params.merchantID}`
      sendRequest(true, false, this, `api/v1/accounting/payout/methods/${query}`, 'get', null, true,
        (response) => {
          this.payouts = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    }
  },
  components: { 
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadPayouts()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
