<template>
  <div id="page-user-view">
    <div class="vx-col flex-1" id="account-info-col-1">
      <form :v-model="data">
      <shipblu-card class="mb-base p-5"  :cardLoading="dataLoading">
        <p class="h4 mb-6">{{$t('Merchant Cash Processing Fees')}}</p>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('Cash processing fees')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-input v-validate="'required|decimal'" :disabled="!editable" name="cash processing fees" v-model="data.cash_processing_fees" class="mt-2 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('cash processing fees')">{{ errors.first('cash processing fees') }}</span>
          </div>
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('Cash processing fees max')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-input v-validate="'required|decimal'" :disabled="!editable" name="cash processing fees max"  v-model="data.cash_processing_fees_max" class="mt-2 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('cash processing fees max')">{{ errors.first('cash processing fees max') }}</span>
          </div>
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('Cash processing percentage')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-input v-validate="'required|decimal'" :disabled="!editable" name="cash processing percentage"  v-model="data.cash_processing_percentage" class="mt-2 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('cash processing percentage')">{{ errors.first('cash processing percentage') }}</span>
          </div>
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('Cash processing threshold')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-input v-validate="'required|decimal'" :disabled="!editable" name="cash processing threshold"  v-model="data.cash_processing_threshold" class="mt-2 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('cash processing threshold')">{{ errors.first('cash processing threshold') }}</span>
          </div>
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('WhatsApp Fees')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-input v-validate="'required|decimal'" :disabled="!editable" name="whatsapp fees"  v-model="data.whatsapp_fees" class="mt-2 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('whatsapp fees')">{{ errors.first('whatsapp fees') }}</span>
          </div>
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('Refunds Credit Limit')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-input v-validate="'required|decimal'" :disabled="!editable || $store.state.AppActiveUser.userRole !== 'admin'" name="refunds credit limit"  v-model="data.refunds_credit_limit" class="mt-2 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('refunds credit limit')">{{ errors.first('refunds credit limit') }}</span>
          </div>
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('Refunds Enabled')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-switch :disabled="!editable || ($store.state.AppActiveUser.userRole !== 'admin' && $store.state.AppActiveUser.userRole !== 'commercial-admin' && editable)" name="refunds enabled"  v-model="data.refunds_enabled" class="mt-2"/>
            <span class="text-danger text-sm" v-show="errors.has('refunds enabled')">{{ errors.first('refunds enabled') }}</span>
          </div>
          <div class="vx-card__header vx-col w-full pt-6">
            <div class="vx-card__title">
              <h4 class="mb-base">{{$t('Merchant Minimum Pickup Orders Fees')}}</h4>
            </div>
          </div>
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('Minimum Pickup orders threshold')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-input v-validate="'numeric'" :disabled="!editable || ($store.state.AppActiveUser.userRole !== 'admin' && $store.state.AppActiveUser.userRole !== 'commercial-admin' && editable)" name="Minimum Pickup orders threshold"  v-model="data.minimum_order_per_pickup" class="mt-2 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('Minimum Pickup orders threshold')">{{ errors.first('Minimum Pickup orders threshold') }}</span>
          </div>
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('Minimum Pickup fees')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-input v-validate="'decimal'" :disabled="!editable || ($store.state.AppActiveUser.userRole !== 'admin' && $store.state.AppActiveUser.userRole !== 'commercial-admin' && editable)" name="Minimum Pickup fees"  v-model="data.pickup_fees" class="mt-2 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('Minimum Pickup fees')">{{ errors.first('Minimum Pickup fees') }}</span>
          </div>
        </div>
        <div v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'commercial-admin'" class="flex flex-wrap items-center justify-end mt-6">
          <vs-button class="ml-auto mt-2" v-if="!editable" @click="editPricing('editable')">{{$t('Edit')}}</vs-button>
          <vs-button class="ml-auto mt-2" v-if="editable" @click="updateMerchant()">{{$t('Save')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="editable" @click="cancelEdit()" type="border" color="warning">{{$t('Cancel')}}</vs-button>
        </div>
      </shipblu-card>
      <shipblu-card class="mb-base p-5"  :cardLoading="dataLoading">
        <p class="h4 mb-6">{{$t('BluCom')}}</p>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('WhatsApp Communication')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-switch :disabled="!editableBlucom"  v-model="bluCom" class="mt-2"/>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('Magic Link')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-switch :disabled="!editableBlucom" v-model="magicLink" class="mt-2"/>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/3">
            <label class="font-semibold">{{$t('RTO Email')}}</label>
          </div>
          <div class="vx-col w-2/3">
            <vs-switch :disabled="!editableBlucom" v-model="rtoEmail" class="mt-2"/>
          </div>
        </div>
        <div v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'commercial-admin'" class="flex flex-wrap items-center justify-end mt-6">
          <vs-button class="ml-auto mt-2" v-if="!editableBlucom" @click="editPricing('editableBlucom')">{{$t('Edit')}}</vs-button>
          <vs-button class="ml-auto mt-2" v-if="editableBlucom" @click="updateMerchant()">{{$t('Save')}}</vs-button>
          <vs-button class="ml-4 mt-2" v-if="editableBlucom" @click="cancelEdit()" type="border" color="warning">{{$t('Cancel')}}</vs-button>
        </div>
      </shipblu-card>
      </form>
    </div>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper'
import i18nData from '../../../i18n/i18nData.js'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'

export default {
  data () {
    return {
      editable: false,
      editableBlucom: false,
      data: {},
      dataLoading: false,
      bluCom: false,
      magicLink: false,
      rtoEmail: false,
      oldPickupOrder: 0,
      oldPickupFees: 0
    }
  },
  methods: {
    editPricing (flag) {
      this[flag] = true
      this.oldPickupOrder = this.data.minimum_order_per_pickup
      this.oldPickupFees = this.data.pickup_fees
    },
    cancelEdit () {
      this.editable = false
      this.editableBlucom = false
      this.loadMerchant()
    },
    loadMerchant () {
      this.dataLoading = true
      sendRequest(true, false, this, `api/v1/merchants/${this.$route.params.merchantID}/`, 'get', null, true,
        (response) => {
          this.data = response.data
          this.bluCom = this.data.blucomm_subscriptions[0].notification_channel === 'whatsapp'
          this.magicLink = this.data.blucomm_subscriptions[0].magic_link
          this.rtoEmail = this.data.blucomm_subscriptions[0].rto_email
          this.dataLoading = false
        } 
      )
    },
    updateMerchant () {
      this.dataLoading = true
      this.$validator.validateAll().then(result => {
        if (result) {
          const cashProcessing = {
            cash_processing_fees: this.data.cash_processing_fees,
            cash_processing_fees_max: this.data.cash_processing_fees_max,
            cash_processing_percentage: this.data.cash_processing_percentage,
            cash_processing_threshold: this.data.cash_processing_threshold,
            pricing_lookup: this.data.pricing_lookup_delivery.google_sheet_url,
            refunds_enabled: this.data.refunds_enabled,
            refunds_credit_limit: this.data.refunds_credit_limit,
            whatsapp_fees: this.data.whatsapp_fees,
            minimum_order_per_pickup:this.data.minimum_order_per_pickup,
            pickup_fees: this.data.pickup_fees,
            blucomm_subscriptions: [
              {
                notification_channel: this.bluCom ? 'whatsapp' : 'sms',
                magic_link: this.magicLink,
                rto_email: this.rtoEmail
              }
            ]
          }
          if ((Number(this.oldPickupOrder) !== Number(this.data.minimum_order_per_pickup) 
            || Number(this.oldPickupFees) !== Number(this.data.pickup_fees)) 
            && this.$store.state.AppActiveUser.userRole === 'commercial-admin') {
            cashProcessing.pricing_update_date = new Date()
          }
          sendRequest(true, false, this, `api/v1/merchants/${this.$route.params.merchantID}/`, 'patch', cashProcessing, true,
            () => {
              this.dataLoading = false
              this.editable = false
              this.editableBlucom = false
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Merchant'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
            }
          )
        }
      })
    }
  },
  components: {
    ShipbluCard
  },
  created () {
    this.loadMerchant()
  }
}
</script>