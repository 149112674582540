<template>
  <div id="page-user-view" class="p-6">
    <h2>{{$t('Merchant Available Balance')}}</h2>
    <p class="my-3 text-base text-darkgray">
      {{ $t('Available To Use') }} = {{ $t('Cash Collection Balance') + ' + ' + $t('Wallet Balance') + ' - ' + $t('Services in Progress') + ' - ' + $t('Unpaid Invoices') + ' - ' + $t('Cash on the way') }}
    </p>
    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      :data="merchantBalance"
      :tableLoader="tableLoader"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Available To Use')}}</shipblu-th>
        <shipblu-th>{{$t('Cash Collection Balance')}}</shipblu-th>
        <shipblu-th>{{$t('Wallet Balance')}}</shipblu-th>
        <shipblu-th class="capitalize">{{$t('Services in Progress')}}</shipblu-th>
        <shipblu-th>{{$t('Unpaid Invoices')}}</shipblu-th>
        <shipblu-th class="capitalize">{{$t('Cash on the way')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].available_to_use">
            {{ Number(data[indextr].available_to_use).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].cash_collection_balance">
            {{ Number(data[indextr].cash_collection_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].wallet_balance">
            {{ Number(data[indextr].wallet_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].services_in_progress">
            {{ Number(data[indextr].services_in_progress).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].unpaid_invoices">
            {{ Number(data[indextr].unpaid_invoices).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].cash_on_the_way">
            {{ Number(data[indextr].cash_on_the_way).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'

export default {
  data () {
    return {
      editable: false,
      merchantBalance: [],
      selected: [],
      tableLoader: false
    }
  },
  methods: {
    loadMerchantBalance () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/merchants/${this.$route.params.merchantID}/fire-power/`, 'get', null, true,
        (response) => {
          this.merchantBalance = [response.data]
          this.tableLoader = false
        }
      )
    }
  },
  components: { 
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd
  },
  created () {
    this.loadMerchantBalance()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
