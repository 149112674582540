<template>
  <div id="merchant-page">
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('Merchant Linked Accounts')}}</h2>
      <span v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'sales' || $store.state.AppActiveUser.userRole === 'commercial-admin'" class="font-medium text-primary cursor-pointer">
        <vs-button
          @click="openMerchantModal('add')"
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          size="large"
        >
        </vs-button>
      </span>
    </div>

    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="linkedAccounts"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Name')}}</shipblu-th>
        <shipblu-th>{{$t('Email')}}</shipblu-th>
        <shipblu-th>{{$t('Access Financials')}}</shipblu-th>
        <shipblu-th>{{$t('Access Payout Methods')}}</shipblu-th>
        <shipblu-th>{{$t('Phone Number')}}</shipblu-th>
        <shipblu-th class="flex justify-center" v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'sales' || $store.state.AppActiveUser.userRole === 'commercial-admin'">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50">
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].first_name }} {{ data[indextr].last_name }}
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].email">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Email')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].email }}
            </p>
          </shipblu-td>
          
          <shipblu-td class="order-6" :data="data[indextr].is_default">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Access Financials')}}</p>
            <span class="ml-6" v-if="data[indextr].access_financials">
              <feather-icon
                icon="CheckCircleIcon"
                svgClasses="h-6 w-7"
                class="text-green"
              />
            </span>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].is_default">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Access Payout Methods')}}</p>
            <span class="ml-6" v-if="data[indextr].access_payout_methods">
              <feather-icon
                icon="CheckCircleIcon"
                svgClasses="h-6 w-7"
                class="text-green"
              />
            </span>
          </shipblu-td>
          
          <shipblu-td class="order-5" :data="data[indextr].phone_number">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Phone Number')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].phone_number }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-2" v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'sales' || $store.state.AppActiveUser.userRole === 'commercial-admin'">
            <div class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span> 
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="openMerchantModal('edit', data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item v-if="$store.state.AppActiveUser.userRole === 'admin'"
                    @click="resetPassword(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Change Password')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'commercial-admin'"
                    @click="deleteMerchant(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <merchant userRole="Merchant" :type="type" :data="merchant" :addUserModal="addUserModal" @addUserModal="addUserModal = $event" @loadData="loadData"></merchant>
    <reset-password userRole="merchants" :resetPass="resetPass" :userData="userData" @loadData="loadData" @resetPass="resetPass = $event"></reset-password>
  </div>
</template>

<script>
import Merchant from '../components/AddMerchantAccount.vue'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ResetPassword from '../components/ResetPassword.vue'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import i18nData from '../../../i18n/i18nData.js'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      merchantURL: 'api/v1/users/merchants/',
      selected: [],
      deleteData: {},
      linkedAccounts: [],
      addUserModal: false,
      type: '',
      merchant: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      resetPass: false,
      userData: {},
      searchInProgress: false,
      tableLoader: false,
      searchedValue: ' '
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.loadData()
    }
  },
  methods: {
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadMerchantLinkedAccountsSearch)
    },
    loadMerchantLinkedAccountsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadData()
    },
    loadData () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?merchant=${this.$route.params.merchantID}&offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}`
      sendRequest(true, false, this, `${this.merchantURL}${query}`, 'get', null, true,
        (response) => {
          this.linkedAccounts = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    openMerchantModal (type, data) {
      this.type = type
      if (type === 'add') {
        this.merchant = Object.assign({})
      } else {
        this.merchant = data
      }
      this.addUserModal = true
    },
    deleteMerchant (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `${this.merchantURL}${this.deleteData.uid}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:'Success',
            text:'Merchant has deleted successfully',
            position: 'top-center'
          })
          this.loadData()
        }
      )
    },
    resetPassword (data) {
      this.resetPass = true
      this.userData = data
    }
  },
  created () {
    this.loadData()
  },
  components: {
    Merchant,
    ResetPassword,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
